.aboutMe {
  width: 100%;
  min-height: 100vh;
  background: rgba(10, 25, 47, 0.95);
  border-top: 1px solid white;
  box-sizing: border-box;
  padding-bottom: 100px;
  .imgLaptopBackground {
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    z-index: -2000;
    filter: none;
    -webkit-filter: grayscale(0);
  }
  .content {
    .title {
      width: 100%;
      text-align: center;
      font-size: 25px;
      //margin-top: 50px;
      padding-top: 120px;
      color: #d1d5e0;
      font-family: 'Fredoka One', cursive;
      //font-weight: bold;
      //color: white;
    }
    .basicDescription {
      padding-top: 20px;
      width: 80%;
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      font-family: 'Outfit', sans-serif;
      color: #8892b0;
      font-size: 18px;
      letter-spacing: 0.4px;
      word-spacing: 1px;
      .left, .right {
        width: 50%;
        margin: 20px;
        text-align: justify;
        a {
          color: #64ffda;
          cursor: pointer;
          font-size: 17px;
          transition: color 0.2s;
          text-decoration: none;
        }
        a:hover {
          color: #fff;
        }
      }
    }
  }
  .technologies {
    //width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-top: 80px;
    font-family: 'Outfit', sans-serif;
    color: #64ffda;
    font-size: 18px;
    letter-spacing: 0.4px;
    word-spacing: 1px;
    width: 40%;
    font-weight: bolder;
    .titleContainer {
      display: flex;
      .left {
        flex-grow: 1;
        hr {
          border: 0;
          margin-right: 20px;
          margin-top: 11px;
          height: 0.5px;
          background: #8892b0;
        }
      }
      .right {
        flex-grow: 1;
        hr {
          border: 0;
          margin-top: 11px;
          margin-left: 20px;
          height: 1px;
          background: #8892b0;
        }
      }
    }
    .container {
      color: #8892b0;
      font-family: 'Source Code Pro', monospace;
      letter-spacing: 0.01px;
      font-size: 15px;
      margin-top: 10px;
      line-height: 25px;
    }
    .normal {
      font-family: 'Outfit', sans-serif;
      color: #8892b0;
      font-size: 16px;
      letter-spacing: 0.8px;
      word-spacing: 2px;
      font-weight: normal;
      line-height: 35px;
      i {
        color: #64ffda;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .aboutMe {
    .content {
      .basicDescription {
        display: block;
        .left, .right {
          margin: 20px 0px 20px 0px;
          width: 100%;
          text-align: center;
          text-align: justify;
          text-justify: inter-word;
        }
      }
    }
    .technologies {
      width: 50%;
      .container {
        div {
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .aboutMe {
    .technologies {
      width: 80%;
    }
  }
}
