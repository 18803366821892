.headerDesktop {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  .content {
    width: 80%;
    background: rgba(255,255,255,0.9);
    margin-top: 30px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 11px 1px rgba(0,0,0,0.3);
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right:30px;
    color: #cc3533;
    .logo {
      margin-top: 12px;
      margin-bottom: 10px;
      font-size: 23px;
      font-weight: bolder;
      display: inline-block;
      font-family: 'Arvo', serif;
      letter-spacing: 1px;
      width: 20%;
      box-sizing: border-box;
    }
    .menus {
      font-family: 'Outfit', sans-serif;
      .item {
        display: inline-block;
        font-weight: bold;
        padding-top: 14px;
        padding-left: 30px;
        padding-right: 30px;
        height: 100%;
        font-size: 18px;
        letter-spacing: 1.5px;
        cursor: pointer;
        box-sizing: border-box;
        transition: background-color 0.2s, color 0.2s;
      }
      .item:hover {
        background: #cc3533;
        color: white;
      }
      .itemBlue:hover {
        background: #0a192f;
      }
    }
    .links {
      width: 20%;
      display: flex;
      justify-content: end;
      .item {
        box-sizing: border-box;
        padding-top: 11px;
        margin-top: 7px;
        margin-bottom: 6px;
        display: inline-block;
        padding-left: 8px;
        padding-right: 8px;
        cursor: pointer;
        transition: background-color 0.2s, color 0.2s;
        border-radius: 5px;
      }
      .item:hover {
        background: #cc3533;
        color: white;
      }
      .itemBlue:hover {
        background: #0a192f;
      }
    }
  }
}
