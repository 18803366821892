.workDone {
  color: white;
  margin: 100px auto 0;
  width: 80%;
  .title {
    width: 100%;
    text-align: center;
    font-size: 25px;
    color: #d1d5e0;
    font-family: 'Fredoka One', cursive;
    margin-bottom: 60px;
  }
  .workItemContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 0.8fr 1.2fr;
    background: rgba(0, 0, 0, 0.4);
    transition: background 0.3s;
    .item-description {
      display: flex;
      align-items: end;
      justify-content: center;
      flex-direction: column;
      margin: 10px 50px;
      .title {
        font-size: 50px;
        color: #64ffda;
        font-family: 'Fredoka One', cursive;
        text-align: right;
        margin-bottom: 30px;
      }
      .description {
        font-family: 'Outfit', sans-serif;
        color: #8892b0;
        letter-spacing: 0.4px;
        word-spacing: 1px;
        text-align: right;
        .text1 {
          font-size: 20px;
        }
        .text2 {
          font-size: 19px;
          color: rgb(136, 146, 176, 0.7);
        }
      }
      .button {
        font-family: 'Outfit', sans-serif;
        color: #64ffda;
        font-size: 18px;
        padding: 8px 20px 11px 20px;
        background: black;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 30px;
        border: 1px solid #64ffda;
        transition: color 0.3s,background 0.3s;
        font-weight: bolder;
        text-decoration: none;
      }
      .button:hover {
        color: black;
        background: #64ffda;
      }
    }
    .item-images {
      display: inline-grid;
      grid-template-columns: auto auto;
      .img {
        width: 100%;
        grid-column: 1;
        grid-row: 1;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        transition: filter 0.3s;
      }
    }
  }
  .workItemContainer:hover {
    background: rgba(0, 0, 0, 0.5);
    .item-images {
      .img {
        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);;
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .workDone {
    .workItemContainer {
      grid-template-columns: 1fr 1fr;
      .item-description {
        .title {
          font-size: 40px;
          margin-bottom: 10px;
        }
        .description {
          .text1 {
            font-size: 20px;
          }

        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .workDone {
    .workItemContainer {
      display: block;
      .item-description {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .title {
          margin-bottom: 10px;
          text-align: center;
          margin-top: 30px;
        }
        .description {
          .text1 {
            text-align: center;
          }
          .text2 {
            text-align: center;
          }
        }
        .button {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
      .item-images {
        display: block;
        width: 100%;

      }
    }
  }
}
