.headerMobile {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  .content {
    width: 100%;
    background: #f2f2f2;
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.5);
    color: #cc3533;
    .logo {
      font-size: 20px;
      font-weight: bolder;
      font-family: 'Arvo', serif;
      letter-spacing: 1px;
      width: 100%;
      box-sizing: border-box;
      background: white;
      display: flex;
      justify-content: space-between;
      .logoText {
        padding: 10px;
        margin: 6px 5px 3px 5px;
      }
      .menuIcon {
        cursor: pointer;
        padding: 10px;
        margin: 5px;
      }
    }
    .menus {
      font-family: 'Outfit', sans-serif;
      text-align: center;
      padding-top: 30px;
      .item {
        font-weight: bolder;
        padding: 15px;
        font-size: 20px;
        letter-spacing: 1.5px;
        cursor: pointer;
        box-sizing: border-box;
        transition: background-color 0.2s, color 0.2s;
      }
      .item:hover {
        background: #cc3533;
        color: white;
      }
      .itemBlue:hover {
        background: #0a192f;
      }
    }
    .links {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 40px;
      .item {
        box-sizing: border-box;
        display: inline-block;
        padding: 14px 11px 11px 11px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
        transition: background-color 0.2s, color 0.2s;
        border-radius: 5px;
      }
      .item:hover {
        background: #cc3533;
        color: white;
      }
      .itemBlue:hover {
        background: #0a192f;
      }
    }
  }

}
