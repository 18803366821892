.video {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1000;
  .video-play{
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 100%;
    min-height: 100%;
  }
}

//@media only screen and (max-width: 1300px) {
//  .video {
//    position: absolute;
//    top: 0;
//    bottom: auto;
//    width: 100%;
//    height: 100%;
//    overflow: hidden;
//    z-index: -1000;
//  }
//}
