.profile {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: rgba(255,102,102,0.8);
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 30px;
  border-bottom: 1px solid white;
  .container {
    width: 80%;
    display: flex;
    .imgCardContainer {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .img1 {
        position: absolute;
        border-radius: 10px;
        width: 60%;
        box-shadow: 0 52px 74px rgba(0,0,0,0.4);
        transform: rotate(5deg);
        opacity: 0.5;
        box-sizing: border-box;

      }
      .img2 {
        position: absolute;
        border-radius: 10px;
        width: 60%;
        box-shadow: 0 52px 74px rgba(0,0,0,0.4);
        transform: rotate(-5deg);
        box-sizing: border-box;
      }
    }
    .description {
      width: 50%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .content1 {
        font-family: 'Fredoka One', cursive;
        font-size: 40px;
        width: 100%;
        text-shadow: #800000 1px 0 10px;
        span {
          color: #64ffda;
        }
      }
      .content2 {
        margin-top: 20px;
        width: 100%;font-family: 'Fredoka One', cursive;
        font-size: 30px;
        text-shadow: #800000 1px 0 10px;
        opacity: 0.8;
      }
      .content3 {
        margin-top: 20px;
        font-size: 21px;
        letter-spacing: 0.5px;
        word-spacing: 8px;
        opacity: 0.8;
        font-family: 'Varela Round', sans-serif;
        font-weight: bolder;
        background-color: rgba(0,0,0,0.1);
        padding: 20px;
        border-radius: 5px;

        text-shadow: #800000 1px 0 10px;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .profile {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: auto;
    padding-bottom: 50px;
    .container {
      width: 80%;
      display: block;
      margin: 0 auto;
      .imgCardContainer {
        position: relative;
        display: block;
        height: 300px;
        width: 295px;
        margin: 0 auto;
        .img1 {
          width: 100%;
          height: 100%;
        }
        .img2 {
          width: 100%;
          height: 100%;
        }
      }
      .description {
        width: 100%;
        text-align: center;
        .content1 {
          margin-top: 50px;
          width: 100%;
        }
        .content2 {
          font-size: 30px;
        }
        .content3 {
          margin-top: 20px;
          font-size: 18px;
          letter-spacing: 1px;
          word-spacing: 5px;
          padding: 15px;
          border-radius: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .profile {
    .container {
      .imgCardContainer {
        height: 200px;
        width: 195px;
      }
      .description {
        .content1 {
          font-size: 30px;
        }
        .content2 {
          font-size: 23px;
        }
        .content3 {
          font-size: 15px;
          letter-spacing: 0.8px;
          word-spacing: 4px;
          padding: 15px;
          border-radius: 5px;
        }
      }
    }
  }
}
