.listSection {
  width: 80%;
  margin: 0 auto;
  .title {
    width: 100%;
    text-align: center;
    font-size: 25px;
    padding-top: 40px;
    color: #d1d5e0;
    font-family: 'Fredoka One', cursive;
    padding-bottom: 30px;
  }
  .container {
    margin: 0 auto;
    width: 80%;
    font-family: 'Outfit', sans-serif;
    color: #8892b0;
    letter-spacing: 0.4px;
    word-spacing: 1px;
    .row {
      width: 100%;
      display: flex;
      .left, .right {
        width: 50%;
        .item {
          font-size: 18px;
          .position {
            font-weight: bolder;
            color: #d1d5e0;
          }
          .company {
            color: #64ffda;
            font-weight: bolder;
            text-decoration: none;
          }
          .duration {
            font-size: 14px;
            font-family: 'Source Code Pro', monospace;
            margin-top: 10px;
            letter-spacing: 0.01px;
          }
          .workList {
            margin-top: 20px;
            font-size: 16px;
            letter-spacing: 0.8px;
            word-spacing: 2px;
            .workItem {
              display: flex;
              justify-content: normal;
              margin-top: 10px;
            }
          }
        }
      }
      .left {
        text-align: right;
        border-right: 1px solid #233554;
        padding-right: 20px;
        .icon-mobile {
          display: none;
        }
        .icon {
          color: #64ffda;
          font-weight: bolder;
          margin-left: 10px;
        }
      }
      .right {
        text-align: left;
        border-left: 1px solid #233554;
        padding-left: 20px;
        .icon-mobile {
          display: none;
        }
        .icon {
          color: #64ffda;
          font-weight: bolder;
          margin-right: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .listSection {
    .container {
      .row {
        display: block;
        margin-top: 30px;
        .left, .right {
          width: 100%;
          padding-left: 0px;
          padding-right: 0px;
          border: 0px;
          .item {
            width: 100%;
            text-align: left;
            padding-right: 0px;
            border: 0px;
            .icon-mobile {
              display: inline-block;
              color: #64ffda;
              font-weight: bolder;
              margin-right: 10px;
            }
            .icon {
              display: none;
            }
            .workList {
              margin-left: 20px;
              .workItem {

              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .listSection {
    .container {
      width: 100%;
      .row {
        .left, .right {
          .item {
            .workList {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
