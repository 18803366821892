.contactMe {
  width: 100%;
  min-height: 100vh;
  background: rgba(10, 25, 47, 0.95);
  position: fixed;
  z-index: 1001;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y:auto;
  overflow-x:hidden;
  display: flex;
  flex-direction: column;
  animation: 0.5s ease-in-out loadContactMe;
  .closeImgContainer {
    text-align: right;
    .closeImg {
      width: 50px;
      margin-right: 50px;
      margin-top: 50px;
      transition: transform 0.5s;
      cursor: pointer;
      color: white;
      font-size: 80px;
    }
    .closeImg:hover {
      transform: rotate(180deg);
    }
  }
  .header {
    font-family: 'Fredoka One', cursive;
    color: white;
    font-size: 50px;
    text-align: center;
    margin-top: 30px;
  }
  .wrapper {
    text-align: center;
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    .contactList {
      font-family: 'Fredoka One', cursive;
      display: inline-block;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 25px;
        padding-top: 25px;
        cursor: pointer;
        .img {
          transition: transform 1s;
          color: white;
          font-size: 40px;
        }
        .text {
          color: white;
          font-size: 30px;
          margin-left: 50px;
          text-decoration: none;
          font-family: 'Fredoka One', cursive;
        }
      }
      .item:hover {
        .img {
          transform: rotate(360deg);
        }
        .img {
          color: #64ffda;
        }
        .text {
          color: #64ffda;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .contactMe {
    .closeImgContainer {
      .closeImg {
        font-size: 50px;
      }
    }
    .header {
      font-size: 40px;
    }
    .wrapper {
      .contactList {
        .item {
          padding-bottom: 15px;
          padding-top: 15px;
          .img {
            font-size: 25px;
          }
          .text {
            margin-left: 20px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

.contactMeUnload {
  animation: 0.5s ease-in-out unLoadContactMe;
}

@keyframes loadContactMe {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes unLoadContactMe {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100vh);
  }
}
